<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout
      v-if="isAdmin()"
      wrap
    >
      <v-flex
        md12
      >
        <material-card
          :title="$t('common.searchData')"
          color="green"
          flat
          full-width
          class="bg-white"
        >
          <v-form>
            <v-container py-0>
              <v-layout wrap>
                <v-flex
                  xs12
                  md4>
                  <v-text-field
                    v-model="entitySearch.text"
                    :label="$t('common.search')"
                    @keyup.enter="onSearch"
                  />
                </v-flex>
                <v-flex
                  xs12
                  md4>
                  <v-select
                    :items="entityNameList"
                    v-model="entitySearch.type"
                    :label="$t('entity.header.type')"
                  >
                    <template
                      slot="selection"
                      slot-scope="data">
                      {{ $t(data.item.text) }}
                    </template>
                    <template
                      slot="item"
                      slot-scope="data">
                      {{ $t(data.item.text) }}
                    </template>
                  </v-select>
                </v-flex>
                <v-flex
                  xs12
                  md4>
                  <v-select
                    :items="entityStatusList"
                    v-model="entitySearch.status"
                    :label="$t('supplier.status')"
                  >
                    <template
                      slot="selection"
                      slot-scope="data">
                      {{ $t(data.item.text) }}
                    </template>
                    <template
                      slot="item"
                      slot-scope="data">
                      {{ $t(data.item.text) }}
                    </template>
                  </v-select>
                </v-flex>
              </v-layout>
              <v-btn
                style="margin-right: 0;"
                class="pull-right"
                color="success"
                @click="onSearch"
                @keyup.enter="onSearch"
              >
                <span class="btn-label mr-3">
                  <i class="glyphicon glyphicon-search" />
                </span>
                <span style="text-transform: none;">{{
                  $t("common.search")
                }}</span>
              </v-btn>
            </v-container>
          </v-form>
        </material-card>
        <material-card
          :title="$t('supplier.supplierList')"
          color="green"
          flat
          full-width
        >
          <v-data-table
            v-if="isAdmin()"
            :no-data-text="$t('common.noDataAvailable')"
            :headers="supplierHeaders"
            :items="suppliersList"
            :single-select="true"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="text--darken-3 font-medium"
                v-text="$t(header.text)"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <td class="text-xs-center">{{ item.stt }}</td>
              <td
                class="cursor-pointer text-success"
                @click="onShowModalViewEntityDetail(item)">{{ item.code }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.email }}</td>
              <td>{{ $t(getEntityTypeName(item.type)) }}</td>
              <td class="text-xs-center">
                <v-tooltip
                  v-if="!item.status"
                  top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      color="error"
                      dark
                      v-on="on">mdi-close-outline</v-icon>
                  </template>
                  <span>{{ $t('evoucher.state.inactive') }}</span>
                </v-tooltip>
                <v-tooltip
                  v-else
                  top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      color="success"
                      dark
                      v-on="on">mdi-check-outline</v-icon>
                  </template>
                  <span>{{ $t('evoucher.state.active') }}</span>
                </v-tooltip>
              </td>
              <td class="text-xs-center">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mx-2"
                      fab
                      small
                      color="success"
                      style="color: #ffffff;"
                      v-on="on"
                      @click="onShowModalViewEntityDetail(item)">
                      <v-icon dark>mdi-account-details</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('evoucher.viewDetail') }}</span>
                </v-tooltip>
              </td>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>
    </v-layout>
    <pagination
      :total="supplierPaginate.totalPage"
      :current-page="supplierPaginate.currentPage"
      :row-per-page="supplierPaginate.rowPerPage"
      @onPageChange="onPageChange"
    />
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"/>
    <api-key-modal ref="secretKeyModal"/>
    <loadingBar :is-loading="isLoading" />
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import functionUtils from 'utils/functionUtils'
import dateUtils from 'utils/dateUtils'
// import ToastType from 'enum/toastType'
import Avatar from 'vue-avatar'
import stringUtils from 'utils/stringUtils'
import ApiKeyModal from './ApiKeyModal'
// import EntityType from 'enum/entityType'
export default {
  components: {
    Avatar,
    ApiKeyModal
  },
  data: () => ({
    supplierHeaders: [
      {
        sortable: false,
        text: 'common.nonumber',
        value: 'stt',
        align: 'center'
      },
      {
        sortable: false,
        text: 'common.code',
        value: 'code'
      },
      {
        sortable: false,
        text: 'actions.name',
        value: 'name'
      },
      {
        sortable: false,
        text: 'userProfile.email',
        value: 'email'
      },
      {
        sortable: false,
        text: 'entity.header.type',
        value: 'type'
      },
      {
        sortable: false,
        text: 'common.state',
        value: 'status',
        align: 'center'
      },
      {
        sortable: false,
        text: 'common.actions',
        value: 'actions',
        align: 'center'
      }
    ],
    supplierPaginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    userSelected: {
      id: null
    },
    isLoading: false,
    entitySearch: {
      type: null,
      text: null,
      status: null
    },
    suppliersList: [],
    entityNameList: [
      {
        text: '---',
        value: null
      },
      ...stringUtils.EntityTypeName
    ],
    entityStatusList: stringUtils.EntityStatus
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR',
      'LIST_ENTITIES_DATA',
      'GET_CURRENT_ACCOUNT'
    ])
  },
  watch: {
    LIST_ENTITIES_DATA () {
      let res = this.LIST_ENTITIES_DATA
      this.supplierPaginate.currentPage = res.paginate.currentPage
      this.supplierPaginate.totalPage = res.paginate.totalPage
      this.supplierPaginate.rowPerPage = res.paginate.perPage
      // Handle data
      let data = res.results
      this.suppliersList = []
      for (let i = 0, size = data.length; i < size; i++) {
        let supplierObj = {
          stt: i + 1 + (res.paginate.currentPage - 1) * res.paginate.pageSize,
          id: data[i].id,
          name: data[i].name,
          code: data[i].code,
          type: data[i].type,
          status: data[i].status,
          phone: data[i].phone,
          email: data[i].email,
          selectedButton: false,
          longitude: data[i].longitude,
          latitude: data[i].latitude,
          province: data[i].province,
          district: data[i].district,
          ward: data[i].ward,
          street: data[i].street,
          description: data[i].description,
          dateCreated: dateUtils.formatBeautyDate(data[i].date_created),
          accessCode: data[i].access_code
        }
        this.suppliersList.push(supplierObj)
      }
      this.isLoading = false
    }
  },
  created () {
    this.getListEntitiesByType()
  },
  methods: {
    // Check is admin
    isAdmin: function () {
      return functionUtils.isAdmin(this.GET_CURRENT_ACCOUNT)
    },
    /**
     * Show modal view detail user
     */
    onShowModalViewEntityDetail: function (item) {
      this.$refs.secretKeyModal.onShowModal(item)
    },
    /**
     * Search
     */
    onSearch: function () {
      this.getListEntitiesByType()
    },
    /**
     * Has action
     */
    hasAction: function (metaCode) {
      return functionUtils.hasAction(this.GET_CURRENT_ACCOUNT, metaCode)
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.supplierPaginate.currentPage = page
      this.getListEntitiesByType()
    },
    getEntityTypeName: function (type) {
      return functionUtils.getEntityTypeName(type)
    },
    getListEntitiesByType: function () {
      let filter = {
        params: {
          page: this.supplierPaginate.currentPage,
          type: this.entitySearch.type,
          entityStatus: this.entitySearch.status,
          searchText: this.entitySearch.text != null ? this.entitySearch.text.trim() : null
        }
      }
      this.isLoading = true
      this.GET_LIST_ENTITIES_BY_TYPE(filter)
        .then(function () {})
        .catch(
          function () {
            this.isLoading = false
          }.bind(this)
        )
    },
    ...mapActions([
      'UPDATE_USER_PROFILE',
      'ON_SHOW_TOAST',
      'ACTIVE_USER',
      'GET_LIST_ENTITIES_BY_TYPE'
    ])
  }
}
</script>
<style lang="scss" scoped>
@import "../../styles/material-dashboard/_colors.scss";
/deep/ .test-primary {
  background-color: #c7c7c7c7 !important;
}
/deep/ .test-primary:hover {
  background-color: #c7c7c7c7 !important;
}
/deep/ .theme--light.v-list {
  background-color: transparent;
}
.border-style {
  border-bottom: 2px solid $border-color;
}
.cursor-pointer {
  cursor: pointer !important;
}
</style>
