<template>
  <v-dialog
    v-model="isShowModal"
    persistent
    max-width="1200px">
    <v-card>
      <material-card
        :title="$t('development.supplierAccessCode') + ' ' + $t(getEntityTypeName(supplierSelected.type)) + ': ' + (supplierSelected.name ? supplierSelected.name : '')"
        tile
        color="green"
        full-width
      >
        <v-layout>
          <v-flex lg6>
            <material-card
              :title="$t('entity.supplier') + ': ' + (supplierSelected.name ? supplierSelected.name : '')"
              color="green"
              flat
              full-width
            >
              <v-layout wrap>
                <v-flex
                  xs12
                  md12>
                  <v-text-field
                    v-model="supplierSelected.email"
                    :label="$t('user.email')"
                    disabled
                  />
                </v-flex>
                <v-flex
                  xs12
                  md12>
                  <v-text-field
                    v-model="supplierSelected.name"
                    :label="$t('entity.supplier')"
                    disabled
                  />
                </v-flex>
                <v-flex
                  xs12
                  md12>
                  <v-text-field
                    v-model="supplierSelected.phone"
                    :label="$t('user.phone')"
                    disabled
                  />
                </v-flex>
              </v-layout>
            </material-card>
          </v-flex>
          <v-flex
            lg6
            class="ml-3"
          >
            <material-card
              :title="$t('development.accessCode.title')"
              color="green"
              flat
              full-width
            >
              <div class="text-right">
                <v-tooltip
                  v-if="isAdmin() && isEmptyString(supplierSelected.accessCode)"
                  top
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      small
                      color="primary"
                      v-on="on"
                      @click="onCreateAccessCode()"
                    >
                      <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("common.add") }}</span>
                </v-tooltip>
                <v-tooltip
                  v-else-if="isAdmin()"
                  top
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      small
                      color="primary"
                      v-on="on"
                      @click="onCreateOtherAccessCode()"
                    >
                      <v-icon dark>mdi-book-refresh</v-icon>
                    </v-btn>
                  </template>
                  <span>{{
                    $t("common.refresh")
                  }}</span>
                </v-tooltip>
                <v-tooltip
                  v-if="!isEmptyString(supplierSelected.accessCode)"
                  top
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      small
                      color="info"
                      v-on="on"
                      @click="copyAccessCode()"
                    >
                      <v-icon dark>mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  <span>{{
                    $t("templateEmail.copy")
                  }}</span>
                </v-tooltip>
              </div>
              <v-layout wrap>
                <v-flex
                  xs12
                  md12>
                  <v-textarea
                    v-model="supplierSelected.accessCode"
                    :label="$t('development.accessCode.title')"
                    disabled
                  />
                </v-flex>
              </v-layout>
            </material-card>
          </v-flex>
        </v-layout>
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal">
          {{ $t("common.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
    <confirm-modal
      ref="CreateAccessCodeConfirmModal"
      :title="$t('development.confirmResetKeyModal')"
      @onConfirm="onCreateAccessCode"
    />
  </v-dialog>
</template>

<script>
import functionUtils from 'utils/functionUtils'
import { mapActions, mapGetters } from 'vuex'
import ToastType from 'enum/toastType'
export default {
  components: {},
  data: () => ({
    isShowModal: false,
    supplierSelected: {
      id: null,
      name: null,
      email: null,
      phone: null,
      accessCode: null
    },
    userPaginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    }
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR',
      'GET_CURRENT_ACCOUNT',
      'GET_ACCESS_CODE_DATA'
    ])
  },
  watch: {
    GET_ACCESS_CODE_DATA: function () {
      this.supplierSelected.accessCode = this.GET_ACCESS_CODE_DATA.access_code
    }
  },
  methods: {
    // Check is admin
    isAdmin: function () {
      return functionUtils.isAdmin(this.GET_CURRENT_ACCOUNT)
    },
    isEmptyString: function (string) {
      return functionUtils.isEmptyString(string)
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
    },
    /**
     * Check null
     */
    isNull: function (data) {
      return functionUtils.isNull(data)
    },
    /**
     * Show modal
     */
    onShowModal: function (item) {
      this.supplierSelected.id = item.id
      this.supplierSelected.name = item.name
      this.supplierSelected.type = item.type
      this.supplierSelected.phone = item.phone
      this.supplierSelected.email = item.email
      this.supplierSelected.accessCode = item.accessCode

      this.isShowModal = true
      let filter = {
        params: {
          entityId: item.id
        }
      }
      this.GET_ACCESS_CODE(filter)
    },
    onCreateAccessCode: function () {
      let data = {
        entityId: this.supplierSelected.id
      }
      this.CREATE_ACCESS_CODE(data).then(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            'styleType': ToastType.SUCCESS
          })
          let filter = {
            params: {
              entityId: this.supplierSelected.id
            }
          }
          this.GET_ACCESS_CODE(filter)
        }.bind(this)
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    onCreateOtherAccessCode: function () {
      this.$refs.CreateAccessCodeConfirmModal.onShowModal()
    },
    copyAccessCode: function () {
      navigator.clipboard.writeText(functionUtils.removeDoubleSlashesFromUrl(this.supplierSelected.accessCode))
    },
    getEntityTypeName: function (type) {
      return functionUtils.getEntityTypeName(type)
    },
    ...mapActions([
      'CREATE_ACCESS_CODE',
      'GET_ACCESS_CODE',
      'ON_SHOW_TOAST'
    ])
  }
}
</script>

<style lang='scss' scoped>
@import '../../styles/material-dashboard/_colors.scss';
/deep/ .theme--light.v-list {
  background-color: transparent;
}
.border-style {
  border-bottom: 2px solid $border-color;
}
.cursor-pointer {
  cursor: pointer !important;
}
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
</style>
